import React, { useState } from "react"
import { Layout } from "@components/Layout"
import FormModal from "../../components/Modal/FormModal"
import ROIHero from "../../components/Calculator/ROIHero"
import { graphql } from "gatsby"
import CardDeck from "../../components/CardDeck/CardDeck"
import ConversionPanel from "../../components/ConversionPanel/ConversionPanel"

export default function ROICalculatorPage({ data }) {
  const {
    contentfulComponentCardDeck,
    contentfulComponentConversionPanel,
  } = data

  const [open, setOpen] = useState(false)
  const [formId, setFormId] = useState()

  const closeModal = () => {
    setOpen(false)
  }

  const openModal = (id) => {
    setFormId(id)
    setOpen(true)
  }

  return (
    <Layout>
      <ROIHero heading="What is the ROI of a Digital Assistant?" openModal={openModal}/>
      <CardDeck component={contentfulComponentCardDeck} />
      <ConversionPanel component={contentfulComponentConversionPanel} />
      {typeof window !== "undefined" && (
        <FormModal 
          show={open} 
          onClose={closeModal} 
          formId={formId}
        />
      )}
    </Layout>
  )
}

export const roiCalculatorPageQuery = graphql`
  query roiCalculatorPageQuery {
    contentfulComponentConversionPanel(
      id: { eq: "d344ab71-7ad7-5a28-a3a8-ca92327554c7" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      noTopPadding
      noBottomPadding
      headingSize
      heading
      type
    }
    contentfulComponentCardDeck(
      id: { eq: "610369ce-9bc7-5f5d-99a5-694749a54e45" }
    ) {
      id
      internalName
      type
      cardType
      backgroundColor
      kicker
      slide2Kicker
      headingSize
      heading
      slide2Heading
      noTopPadding
      noBottomPadding
      subheading {
        subheading
      }
      slide2Subheading {
        slide2Subheading
      }
      post {
        ... on ContentfulResource {
          __typename
          id
          title
          alternateTitle
          description {
            description
          }
          category {
            title
            id
          }
          externalUrl
          slug
          featuredImage {
            gatsbyImageData
            title
            url
            description
          }
          infographicImage {
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
        ... on ContentfulBlogPost {
          __typename
          id
          title
          alternateTitle
          externalTitle
          publishDate
          slug
          body {
            raw
          }
          description {
            description
          }
          category {
            backgroundColor
            fontColor
            title
            id
          }
          author {
            fullName
            headshot {
              file {
                url
              }
            }
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulKnowledgeCenterPost {
          __typename
          id
          title
          alternateTitle
          slug
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          category {
            title
            id
          }
          body {
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                  description
                }
              }
            }
          }
        }
        ... on ContentfulNewsroomPost {
          __typename
          id
          alternateTitle
          title
          externalTitle
          publishDate
          slug
          description {
            description
          }
          category {
            title
            id
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulComponentTile {
          __typename
          internalName
          category {
            title
            id
          }
          heading
          alternateHeading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
      slide2Post {
        ... on ContentfulResource {
          __typename
          id
          title
          alternateTitle
          description {
            description
          }
          category {
            title
            id
          }
          externalUrl
          slug
          featuredImage {
            gatsbyImageData
            title
            url
            description
          }
          infographicImage {
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
        ... on ContentfulBlogPost {
          __typename
          id
          title
          alternateTitle
          externalTitle
          publishDate
          slug
          body {
            raw
          }
          description {
            description
          }
          category {
            backgroundColor
            fontColor
            title
            id
          }
          author {
            fullName
            headshot {
              file {
                url
              }
            }
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulKnowledgeCenterPost {
          __typename
          id
          title
          alternateTitle
          slug
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          category {
            title
            id
          }
          body {
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                  description
                }
              }
            }
          }
        }
        ... on ContentfulNewsroomPost {
          __typename
          id
          alternateTitle
          title
          externalTitle
          publishDate
          slug
          description {
            description
          }
          category {
            title
            id
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulComponentTile {
          __typename
          internalName
          category {
            title
            id
          }
          heading
          alternateHeading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
  }
`
